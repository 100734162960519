import React, {useState} from "react";
import {
    Box,
    Button,
    Container,
    Grid,
    Snackbar,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import header from "../assets/header.jpg";
import logo from "../assets/logo.png";
import {StreamingContent} from "../components/StreamingContent";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Streaming = () => {
    const [loggedIn, setLoggedIn] = useState(false)
    const [password, setPassword] = useState('')
    const [error, setError] = useState(false)
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up('sm'))

    const handleSetPassword = (event) => {
        setPassword(event.target.value)
    }

    const tryLogin = () => {
        setLoggedIn(true)
    }

    return (
        <Box className={loggedIn ? 'streaming' : ''}>
            <Snackbar open={error} autoHideDuration={3000} onClose={() => setError(false)}>
                <Alert onClose={() => setError(false)} severity="error"
                       sx={{width: '100%', border: 'none', fontWeight: '600'}}>
                    La password è sbagliata
                </Alert>
            </Snackbar>
            {!loggedIn &&
                <Box>
                    <img src={header} style={{width: '100%'}} id={'back-to-top-anchor'}/>
                    <Container sx={{mt: 1}} maxWidth={"md"}>
                        <Typography variant={matches ? 'h5' : 'h6'} fontWeight={'600'} textAlign={'center'}>
                            Grazie per aver partecipato!
                        </Typography>
                        {/*<Button onClick={tryLogin} sx={{width: '7rem', fontWeight: '600'}}
                                 variant={"contained"}>Entra</Button>*/}
                    </Container>
                </Box>
            }
            {loggedIn &&
                <StreamingContent/>
            }
        </Box>
    )
}
