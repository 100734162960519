import React from "react";
import {Box} from "@mui/material";
import {StreamingContent} from "../components/StreamingContent";

export const Test = () => {
  return(
      <Box className={'streaming'}>
          <StreamingContent/>
      </Box>
  )
}
