import React, {useState} from "react";
import {
    Box,
    Button,
    CircularProgress,
    Container,
    FormControl,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import header from '../assets/header.jpg'
import footer from '../assets/footer.jpg'
import Title from "../components/Title";
import FormSelezionaPresenza from "../components/FormSelezionaPresenza";
import TuoiDati from "../components/TuoiDati";
import DatiBambini from "../components/DatiBambini";
import NoteAllergie from "../components/NoteAllergie";
import PrivacyForm from "../components/PrivacyForm";
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Home = () => {
    const [presenza, setPresenza] = useState(null)
    const [sede, setSede] = React.useState('');
    const [ora, setOra] = useState('')
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up('sm'))
    const [dati, setDati] = useState({
        name: '',
        surname: '',
        email: '',
        societa: '',
        luogo: ''
    })
    const [privacy, setPrivacy] = useState({
        trattamento: null,
        tutore: null,
        ultimo: null
    })
    const [bambini, setBambini] = useState([
        {
            id: 0,
            name: '',
            age: ''
        }
    ])
    const [allergie, setAllergie] = useState('')
    const [privacyError, setPrivacyError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [message, setMessage] = useState('')

    const addBambino = () => {
        let newBambino =
            {
                id: bambini.length,
                name: '',
                age: ''
            }
        setBambini([...bambini, newBambino])
    }

    const handleDatiBambino = (event, type, id) => {
        let b = bambini
        for (const obj of b) {
            if (obj.id === id) {
                obj[type] = event.target.value;
                break;
            }
        }
        setBambini([...b])
    }

    const handleSetPrivacy = (event, type) => {
        setPrivacy({...privacy, [type]: event.target.value})
    }

    const handleSetSede = (event) => {
        setSede(event.target.value);
    };

    const handleResetSede = () => {
        setSede('');
    };

    const handleSetOra = (event) => {
        setOra(event.target.value)
    }

    const handleSetDati = (event, dato) => {
        setDati({...dati, [dato]: event.target.value})
    }

    const handleSetPresenza = (event, presente) => {
        setPresenza(presente)
        const anchor = (event.target.ownerDocument || document).querySelector(
            '#back-to-top-anchor',
        );

        if (anchor) {
            anchor.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    }

    const handleSetAllergie = (event) => {
        setAllergie(event.target.value)
    }

    const sendData = async (e) => {
        e.preventDefault()
        setPrivacyError(false)
        setSuccess(false)
        setError(false)
        let d = {
            sede: sede,
            ora: ora,
            ...dati,
            bambini: bambini,
            allergie: allergie,
            in_presence: presenza
        }
        if (privacy.trattamento === 'no' || privacy.ultimo === 'no' || Object.values(privacy).includes(null)) {
            setPrivacyError(true)
        } else {
            setLoading(true)
            await axios.post('https://api-edison-bimbi-zqfgmdy37a-ey.a.run.app/register', d)
                .then(response => setSuccess(true))
                .catch(error => {
                    setMessage(error.response.data.error)
                    setError(true)
                })
            setLoading(false)
        }
    }

    return (
        <Box style={{width: '100%'}}>
            <img src={header} style={{width: '100%'}} id={'back-to-top-anchor'}/>
            <Title/>
            <Container sx={{mt: 1}} maxWidth={"md"}>
                <Typography variant={matches ? 'h5' : 'h6'} fontWeight={'600'} textAlign={'center'}>
                    Le registrazioni sono chiuse
                    <br/>
                    grazie per aver partecipato!
                </Typography>
                {/*<Button onClick={tryLogin} sx={{width: '7rem', fontWeight: '600'}}
                                 variant={"contained"}>Entra</Button>*/}
            </Container>
        </Box>
    )
}

export default Home
