import React, {useState} from "react";
import header from "../assets/header.jpg";
import {Backdrop, Box, Button, CircularProgress, Container, Snackbar, Stack, Typography} from "@mui/material";
import axios from "axios";
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const InvioFoto = () => {
    const [foto, setFoto] = useState(null)
    const [url, setUrl] = useState('')
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [message, setMessage] = useState('')

    const handleCompileFoto = (e) => {
        const [f] = e.target.files
        setFoto(f)
        setUrl(URL.createObjectURL(f))
    }

    const sendPhoto = async () => {
        setSending(true)
        const formData = new FormData();
        formData.append('file', foto);
        await axios({
            url: 'https://api-plesh-mfnz5eq5hq-ey.a.run.app/upload',
            method: 'POST',
            data: formData,
        }).then(() => {
            setSuccess(true)
            setFoto(null)
            setUrl('')
            setMessage("Foto inviata")
        })
            .catch(() => {
                setError(true)
                setMessage("C'è stato un errore, riprova")
            })
        setSending(false)
    }

    return (
        <Box sx={{mb:4}}>
            <Snackbar open={success} autoHideDuration={3000} onClose={()=>setSuccess(false)}>
                <Alert onClose={()=>setSuccess(false)} severity="success" sx={{ width: '100%', border:'none' , fontWeight:'600'}}>
                    {message}
                </Alert>
            </Snackbar>
            <Snackbar open={error} autoHideDuration={3000} onClose={()=>setError(false)}>
                <Alert onClose={()=>setError(false)} severity="error" sx={{ width: '100%' , border:'none' , fontWeight:'600'}}>
                    {message}
                </Alert>
            </Snackbar>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 , background:"rgba(0,0,0,0.5)"}}
                open={sending}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <img src={header} style={{width: '100%'}}/>
            <Container>
                <Stack direction={"column"} spacing={4} sx={{mt:2}}>
                    <Box sx={{width: '100%', fontWeight: '600', textAlign:'center', borderRadius:'3rem', border:'2px dashed #072c6c', padding:'1rem'}} component="label">
                        {foto === null ? 'Scegli una foto' : <img style={{width: '100%', maxWidth: '10rem', margin:'auto'}} src={url}/>}
                        <input type="file" hidden onChange={handleCompileFoto}/>
                    </Box>

                    <Button onClick={sendPhoto} disabled={foto===null} variant={"contained"} sx={{width:'100%', fontWeight:'600'}}>Invia la foto</Button>
                </Stack>
            </Container>
        </Box>
    )
}
