import React from "react";
import {Box, Container, Grid} from "@mui/material";

export const StreamingContent = () =>{
    return(
        <Box>
            <Container maxWidth={'xl'} sx={{height: {xs:'100vh',lg:'100%'}}}>
                <Grid container spacing={2} sx={{flexDirection:'row'}} alignItems={"stretch"}>
                    <Grid xs={12} lg={9} item>
                        <div style={{
                            position: 'relative',
                            paddingBottom: '56.25%',
                            height: 0,
                            overflow: 'hidden',
                            width: '100%'
                        }}>
                            <iframe
                                src="https://vimeo.com/event/2147492/embed"
                                allowFullScreen
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    border: "none"
                                }}></iframe>
                        </div>
                    </Grid>
                    <Grid xs={12} lg={3} item>
                        <Box sx={{width:'100%', height: {xs:'60vh',lg:'100%'}}} position={'relative'}>
                            <div style={{height: '27px', background: "white", width: '100%', position: 'absolute', bottom:'0', zIndex:'99'}}/>
                            <iframe src='https://go.arena.im/embed/chat/plesh/nFyXTyY' style={{border: '0', width: '1px', minWidth: '100%', height: '100%', borderRadius: '4px', marginBottom:'-1rem'}}></iframe>
                        </Box>
                    </Grid>

                </Grid>
            </Container>
        </Box>
    )
}
