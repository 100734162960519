import {createTheme} from "@mui/material";

export const edisonTheme = createTheme({
    typography: {
        fontFamily: ['Open Sans']
    },


    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius:0,
                    border:'3px solid #072c6c'
                }
            }
        },
        MuiFormControlLabel:{
            styleOverrides:{
                label:{
                    fontSize: 'min(1rem, 3.8vw)'
                }
            }
        },
        MuiBackdrop: {
            styleOverrides: {
                root:{
                    background:"rgba(255,255,255,0.5)"
                }
            }
        },
        MuiInputBase: {
          styleOverrides: {
              root: {
                  width:'100%',
                  border:'2px solid #072c6c',
                  borderRadius:'0',
              }
          }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    width:'100%',
                    border:'none',
                    borderRadius:'0',
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    width:'100%',
                    border:'2px solid #072c6c',
                    borderRadius:'0',
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    width:'100%',
                    border:'2px solid #072c6c',
                    borderRadius:'0',
                }
            }
        },
        MuiButton: {
            variants: [
                //iscriviti
                {
                    props: {variant: 'iscriviti'},
                    style: {
                        width: '24.75rem',
                        height: '6.25rem',
                        borderRadius: 10,
                        border: '0.4rem solid #072c6c',
                        background:'#ff5717',
                        color: "white !important",
                        fontWeight:'bold',
                        fontSize:'1.8rem',
                        '&:hover':{
                            border: '0.4rem solid #ff5717',
                            background:'#072c6c',
                        }
                    },
                },
                {
                    props: {variant: 'iscriviti', selected:'true'},
                    style:{
                        border: '0.4rem solid #ff5717',
                        background:'#072c6c',
                    }
                },
                {
                    props: {variant: 'addBambino'},
                    style:{
                        borderBottom: '0.2rem solid #072c6c',
                        borderRadius: 0,
                        fontWeight: "600"
                    }
                }
            ]
        }
    },

    palette: {
        mode: 'light',
        primary: {
            main: '#072c6c',
        },
        /*
        secondary: {
            main: '#263238',
        },
        background: {
            default:'#131331',
        },
        accent: {
            main: '#e6fc69',
        },
        text: {
            primary: '#eceff1',
        },*/
    },
});
