import React from "react";
import {Box} from "@mui/material";

const Title = () => (
    <Box style={{textAlign:'center'}}>
        <p style={{fontSize:'min(2rem,6vw)', margin:'0.8rem'}}>
            BIMBI IN UFFICIO | 24 maggio
        </p>
    </Box>

)

export default Title
