import './App.css';
import {CssBaseline, ThemeProvider} from "@mui/material";
import {edisonTheme} from "./theme/edisonTheme";
import Home from "./pages/Home";
import {Route, Routes} from "react-router-dom";
import {Streaming} from "./pages/Streaming";
import {InvioFoto} from "./pages/InvioFoto";
import {Test} from "./pages/Test";


function App() {
    return (
        <ThemeProvider theme={edisonTheme}>
            <CssBaseline/>
            <div className="App" style={{color: '#212e6d', fontWeight:'600'}}>
                <Routes>
                    <Route path={'/'} element={<Home/>}/>
                    <Route path={'/streaming'} element={<Streaming/>}/>
                    <Route path={'/invio-foto'} element={<InvioFoto/>}/>
                    <Route path={'/test'} element={<Test/>}/>
                </Routes>
            </div>
        </ThemeProvider>
    );
}

export default App;
